document.addEventListener('DOMContentLoaded', function () {
    new Swiper('.tariffsSection__swiper', {
        pagination: {
            el: '.tariffsSection__swiperPagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.tariffsSection__swiperButtonNext',
            prevEl: '.tariffsSection__swiperButtonPrev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 2,
            },
            950: {
                slidesPerView: 3,
            },
        },
    });

    // Flip tariffs slider if width <= 500
    if ($(window).width() <= 500) {
        const slide1 = $($('.tariffsSection .swiper-slide')[0]).html();
        const slide2 = $($('.tariffsSection .swiper-slide')[1]).html();
        $($('.tariffsSection .swiper-slide')[0]).html(slide2);
        $($('.tariffsSection .swiper-slide')[1]).html(slide1);
    }
});
